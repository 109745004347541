









































































import { Component, Vue } from 'vue-property-decorator'
import InfoCard from '@/general/components/common/InfoCard.vue'
import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from 'vue2-leaflet'

@Component({
  components: {
    InfoCard,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
  }
})
export default class Map extends Vue {
  // gps: GPSDTO[] = []
  // stats = {}
  // center = ["52.3790532", "4.8981734"]
  // users: CompanyAppUserDTO[] = []
  // user: string = ''
  //
  // created() {
  //   this.getGPSPoints()
  //   this.getStats()
  //   this.getUsers()
  //   this.$websocket.eventEmitter.on('gps', this.newGPSEntry)
  // }
  //
  // destroyed() {
  //   this.$websocket.eventEmitter.removeListener('gps', this.newGPSEntry)
  // }
  //
  // newGPSEntry(rawGPS: string) {
  //   const gps: GPSDTO = JSON.parse(rawGPS)
  //   if (this.user) {
  //     this.gps.push(gps)
  //   } else {
  //     const old = this.gps.find(g => g.appUser === gps.appUser)
  //     if (old) {
  //       const index = this.gps.indexOf(old)
  //       this.gps[index] = gps
  //     } else {
  //       this.gps.push(gps)
  //     }
  //   }
  //   this.centerMap(gps)
  // }
  //
  // centerMap(gps: GPSDTO) {
  //   this.center = [gps.latitude, gps.longitude]
  // }
  //
  // toHex(s: string) {
  //   let i
  //   let hash = 0
  //   if (s.length === 0) return hash;
  //   for (i = 0; i < s.length; i++) {
  //     hash = s.charCodeAt(i) + ((hash << 5) - hash);
  //     hash = hash & hash;
  //   }
  //   let color = '#'
  //   for (i = 0; i < 3; i++) {
  //     const value = (hash >> (i * 8)) & 255
  //     color += ('00' + value.toString(16)).substr(-2);
  //   }
  //   return color;
  // }
  //
  // refresh() {
  //   this.getGPSPoints()
  //   this.getStats()
  // }
  //
  // getGPSPoints() {
  //   (this.user ? getUserGPS(this.user) : getGPS()).then((response: AxiosResponse) => {
  //     this.gps = response.data
  //     if (this.gps.length) {
  //       const newest = this.gps.sort((a, b) => a.date < b.date ? 1 : -1)[0]
  //       this.centerMap(newest)
  //     }
  //   })
  // }
  //
  // getStats() {
  //   (this.user ? getUserStats(this.user) : getStats()).then((response: AxiosResponse) => {
  //     this.stats = response.data
  //   })
  // }
  //
  // getUsers() {
  //   const pagination = new BackendPagination(0, -1)
  //   pagination.sort = 'email,asc'
  //   getAll(undefined, pagination).then(response => {
  //     this.users = response.data.users.content
  //   })
  // }
}
